import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { SplashScreenOrganism } from 'components/atomic/organisms';
import { useUIContext } from 'components/context/ManagedUIContext';
import { isAuthenticated } from 'services/identity.service';

export default function Home() {
  const router = useRouter();
  const { redirectAuthenticatedUser } = useUIContext();

  const isAuthenticatedUser = isAuthenticated();

  useEffect(() => {
    isAuthenticatedUser && redirectAuthenticatedUser({ router });
  }, [isAuthenticatedUser]);

  return <SplashScreenOrganism />;
}
